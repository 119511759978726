import { x } from '@xstyled/styled-components';
import { Article, ScreenContainer, Button } from 'smooth-doc/components';
import notFoundImageURL from "../../../../images/404.png";
import * as React from 'react';
export default {
  x,
  Article,
  ScreenContainer,
  Button,
  notFoundImageURL,
  React
};