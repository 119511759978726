import { ScreenContainer, Button, HeroSection, Hero, HeroBody, HeroTitle, HeroTeaser, HeroActionList, HeroAction } from "smooth-doc/components";
import StoreFront from "../../../../src/components/store";
import * as React from 'react';
export default {
  ScreenContainer,
  Button,
  HeroSection,
  Hero,
  HeroBody,
  HeroTitle,
  HeroTeaser,
  HeroActionList,
  HeroAction,
  StoreFront,
  React
};